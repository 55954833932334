<template>
  <div class="module-simple-modal" :style="`--max-width: ${maxWidth}px`">
    <slot
      name="modal-header"
      :hasSeparator="false"
      :titleText="titleText"
      :titleStyle="modalTitleStyle"
    />
    <div class="content-wrapper">{{ descriptionText }}</div>
    <div class="button-row">
      <button-base v-if="primaryBtnText" :text="primaryBtnText" @clicked="handleBtnClick('primary')" />
      <button-base v-if="secondaryBtnText" :isSecondary="true" :text="secondaryBtnText" @clicked="handleBtnClick('secondary')" />
    </div>
  </div>
</template>

<script>
import ButtonBase from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'SimpleModal',
  props: {
    titleText: {
      type: String,
    },
    descriptionText: {
      type: String,
    },
    primaryBtnText: {
      type: String,
    },
    secondaryBtnText: {
      type: String,
    },
    modalTitleStyle: {
      type: Object,
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 325,
    },
  },
  components: {
    ButtonBase,
  },
  methods: {
    handleBtnClick(key) {
      switch (key) {
        case 'primary':
          this.$emit('primaryButtonClicked');
          break;
        case 'secondary':
          this.$emit('secondaryButtonClicked');
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.module-simple-modal {
  text-align: center;
  max-width: var(--max-width);
  padding: 0 40px 25px;
}

.content-wrapper {
  color: $globalFontColor;
  font-size: 12px;
  line-height: 17px;
}

.button-row {
  margin: 20px 0 0;
  display: flex;
  justify-content: space-between;

  .rounded-button {
    padding: 0;
  }
}
</style>
